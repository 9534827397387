import { A as isPositiveNumber, B as isNumber, t as isDefined } from './element-d46853d9.js';
import { D as DATE_SEPARATOR, p as parse, i as isMatch, b as isAfter, c as isBefore } from './index-decf52b2.js';
import { r as requiredArgs, a as addMilliseconds, t as toInteger, b as toDate, l as locale, i as isValid } from './index-f6ab9135.js';
import { s as setMonth } from './index-42097b04.js';
import { s as startOfDay } from './index-06d19404.js';
import { e as endOfDay } from './index-17b06582.js';

var MILLISECONDS_IN_MINUTE = 60000;
/**
 * @name addMinutes
 * @category Minute Helpers
 * @summary Add the specified number of minutes to the given date.
 *
 * @description
 * Add the specified number of minutes to the given date.
 *
 * ### v2.0.0 breaking changes:
 *
 * - [Changes that are common for the whole library](https://github.com/date-fns/date-fns/blob/master/docs/upgradeGuide.md#Common-Changes).
 *
 * @param {Date|Number} date - the date to be changed
 * @param {Number} amount - the amount of minutes to be added. Positive decimals will be rounded using `Math.floor`, decimals less than zero will be rounded using `Math.ceil`.
 * @returns {Date} the new date with the minutes added
 * @throws {TypeError} 2 arguments required
 *
 * @example
 * // Add 30 minutes to 10 July 2014 12:00:00:
 * const result = addMinutes(new Date(2014, 6, 10, 12, 0), 30)
 * //=> Thu Jul 10 2014 12:30:00
 */

function addMinutes(dirtyDate, dirtyAmount) {
  requiredArgs(2, arguments);
  var amount = toInteger(dirtyAmount);
  return addMilliseconds(dirtyDate, amount * MILLISECONDS_IN_MINUTE);
}

/**
 * @name startOfMinute
 * @category Minute Helpers
 * @summary Return the start of a minute for the given date.
 *
 * @description
 * Return the start of a minute for the given date.
 * The result will be in the local timezone.
 *
 * ### v2.0.0 breaking changes:
 *
 * - [Changes that are common for the whole library](https://github.com/date-fns/date-fns/blob/master/docs/upgradeGuide.md#Common-Changes).
 *
 * @param {Date|Number} date - the original date
 * @returns {Date} the start of a minute
 * @throws {TypeError} 1 argument required
 *
 * @example
 * // The start of a minute for 1 December 2014 22:15:45.400:
 * const result = startOfMinute(new Date(2014, 11, 1, 22, 15, 45, 400))
 * //=> Mon Dec 01 2014 22:15:00
 */

function startOfMinute(dirtyDate) {
  requiredArgs(1, arguments);
  var date = toDate(dirtyDate);
  date.setSeconds(0, 0);
  return date;
}

/**
 * @name eachMinuteOfInterval
 * @category Interval Helpers
 * @summary Return the array of minutes within the specified time interval.
 *
 * @description
 * Returns the array of minutes within the specified time interval.
 *
 * @param {Interval} interval - the interval. See [Interval]{@link https://date-fns.org/docs/Interval}
 * @param {Object} [options] - an object with options.
 * @param {Number} [options.step=1] - the step to increment by. The starts of minutes from the hour of the interval start to the hour of the interval end
 * @throws {TypeError} 1 argument requie value should be more than 1.
 * @returns {Date[]} the array withred
 * @throws {RangeError} `options.step` must be a number equal or greater than 1
 * @throws {RangeError} The start of an interval cannot be after its end
 * @throws {RangeError} Date in interval cannot be `Invalid Date`
 *
 * @example
 * // Each minute between 14 October 2020, 13:00 and 14 October 2020, 13:03
 * const result = eachMinuteOfInterval({
 *   start: new Date(2014, 9, 14, 13),
 *   end: new Date(2014, 9, 14, 13, 3)
 * })
 * //=> [
 * //   Wed Oct 14 2014 13:00:00,
 * //   Wed Oct 14 2014 13:01:00,
 * //   Wed Oct 14 2014 13:02:00,
 * //   Wed Oct 14 2014 13:03:00
 * // ]
 */
function eachMinuteOfInterval(interval, options) {
  requiredArgs(1, arguments);
  var startDate = startOfMinute(toDate(interval.start));
  var endDate = startOfMinute(toDate(interval.end));
  var startTime = startDate.getTime();
  var endTime = endDate.getTime();

  if (startTime >= endTime) {
    throw new RangeError('Invalid interval');
  }

  var dates = [];
  var currentDate = startDate;
  var step = options && 'step' in options ? Number(options.step) : 1;
  if (step < 1 || isNaN(step)) throw new RangeError('`options.step` must be a number equal or greater than 1');

  while (currentDate.getTime() <= endTime) {
    dates.push(toDate(currentDate));
    currentDate = addMinutes(currentDate, step);
  }

  return dates;
}

/**
 * @name getHours
 * @category Hour Helpers
 * @summary Get the hours of the given date.
 *
 * @description
 * Get the hours of the given date.
 *
 * ### v2.0.0 breaking changes:
 *
 * - [Changes that are common for the whole library](https://github.com/date-fns/date-fns/blob/master/docs/upgradeGuide.md#Common-Changes).
 *
 * @param {Date|Number} date - the given date
 * @returns {Number} the hours
 * @throws {TypeError} 1 argument required
 *
 * @example
 * // Get the hours of 29 February 2012 11:45:00:
 * const result = getHours(new Date(2012, 1, 29, 11, 45))
 * //=> 11
 */

function getHours(dirtyDate) {
  requiredArgs(1, arguments);
  var date = toDate(dirtyDate);
  var hours = date.getHours();
  return hours;
}

/**
 * @name getMinutes
 * @category Minute Helpers
 * @summary Get the minutes of the given date.
 *
 * @description
 * Get the minutes of the given date.
 *
 * ### v2.0.0 breaking changes:
 *
 * - [Changes that are common for the whole library](https://github.com/date-fns/date-fns/blob/master/docs/upgradeGuide.md#Common-Changes).
 *
 * @param {Date|Number} date - the given date
 * @returns {Number} the minutes
 * @throws {TypeError} 1 argument required
 *
 * @example
 * // Get the minutes of 29 February 2012 11:45:05:
 * const result = getMinutes(new Date(2012, 1, 29, 11, 45, 5))
 * //=> 45
 */

function getMinutes(dirtyDate) {
  requiredArgs(1, arguments);
  var date = toDate(dirtyDate);
  var minutes = date.getMinutes();
  return minutes;
}

/**
 * @name set
 * @category Common Helpers
 * @summary Set date values to a given date.
 *
 * @description
 * Set date values to a given date.
 *
 * Sets time values to date from object `values`.
 * A value is not set if it is undefined or null or doesn't exist in `values`.
 *
 * Note about bundle size: `set` does not internally use `setX` functions from date-fns but instead opts
 * to use native `Date#setX` methods. If you use this function, you may not want to include the
 * other `setX` functions that date-fns provides if you are concerned about the bundle size.
 *
 * @param {Date|Number} date - the date to be changed
 * @param {Object} values - an object with options
 * @param {Number} [values.year] - the number of years to be set
 * @param {Number} [values.month] - the number of months to be set
 * @param {Number} [values.date] - the number of days to be set
 * @param {Number} [values.hours] - the number of hours to be set
 * @param {Number} [values.minutes] - the number of minutes to be set
 * @param {Number} [values.seconds] - the number of seconds to be set
 * @param {Number} [values.milliseconds] - the number of milliseconds to be set
 * @returns {Date} the new date with options set
 * @throws {TypeError} 2 arguments required
 * @throws {RangeError} `values` must be an object
 *
 * @example
 * // Transform 1 September 2014 into 20 October 2015 in a single line:
 * var result = set(new Date(2014, 8, 20), { year: 2015, month: 9, date: 20 })
 * //=> Tue Oct 20 2015 00:00:00
 *
 * @example
 * // Set 12 PM to 1 September 2014 01:23:45 to 1 September 2014 12:00:00:
 * var result = set(new Date(2014, 8, 1, 1, 23, 45), { hours: 12 })
 * //=> Mon Sep 01 2014 12:23:45
 */
function set(dirtyDate, values) {
  requiredArgs(2, arguments);

  if (typeof values !== 'object' || values === null) {
    throw new RangeError('values parameter must be an object');
  }

  var date = toDate(dirtyDate); // Check if date is Invalid Date because Date.prototype.setFullYear ignores the value of Invalid Date

  if (isNaN(date.getTime())) {
    return new Date(NaN);
  }

  if (values.year != null) {
    date.setFullYear(values.year);
  }

  if (values.month != null) {
    date = setMonth(date, values.month);
  }

  if (values.date != null) {
    date.setDate(toInteger(values.date));
  }

  if (values.hours != null) {
    date.setHours(toInteger(values.hours));
  }

  if (values.minutes != null) {
    date.setMinutes(toInteger(values.minutes));
  }

  if (values.seconds != null) {
    date.setSeconds(toInteger(values.seconds));
  }

  if (values.milliseconds != null) {
    date.setMilliseconds(toInteger(values.milliseconds));
  }

  return date;
}

/**
 * @name subMinutes
 * @category Minute Helpers
 * @summary Subtract the specified number of minutes from the given date.
 *
 * @description
 * Subtract the specified number of minutes from the given date.
 *
 * ### v2.0.0 breaking changes:
 *
 * - [Changes that are common for the whole library](https://github.com/date-fns/date-fns/blob/master/docs/upgradeGuide.md#Common-Changes).
 *
 * @param {Date|Number} date - the date to be changed
 * @param {Number} amount - the amount of minutes to be subtracted. Positive decimals will be rounded using `Math.floor`, decimals less than zero will be rounded using `Math.ceil`.
 * @returns {Date} the new date with the minutes subtracted
 * @throws {TypeError} 2 arguments required
 *
 * @example
 * // Subtract 30 minutes from 10 July 2014 12:00:00:
 * const result = subMinutes(new Date(2014, 6, 10, 12, 0), 30)
 * //=> Thu Jul 10 2014 11:30:00
 */

function subMinutes(dirtyDate, dirtyAmount) {
  requiredArgs(2, arguments);
  var amount = toInteger(dirtyAmount);
  return addMinutes(dirtyDate, -amount);
}

const FORMAT_MAP = {
  hour12: 'hh',
  hour24: 'HH',
  minute: 'mm',
  dayPeriod: 'aaa'
};
const handleLocation = (native, locale$1 = locale) => {
  // if native not need to define locale
  if (native) {
    return null;
  }
  return locale$1;
};
/** This method find computer time format */
const defineFormat = (native, date) => {
  // native mode is using HH:mm format by default
  if (native) {
    return `${FORMAT_MAP.hour24}:${FORMAT_MAP.minute}`;
  }
  /* note: to take default time format from the browser */
  const formatter = new Intl.DateTimeFormat([], { hour: 'numeric', minute: 'numeric' });
  const localeFormat = formatter.formatToParts(new Date(date)); // find the format
  /* if is true time period is 12 otherwise 24 */
  const hasDayPeriod = Object.keys(localeFormat).some(item => localeFormat[item].type === 'dayPeriod');
  let browserFormat = '';
  for (const key of Object.keys(localeFormat)) {
    if (localeFormat[key].type === 'hour') {
      if (hasDayPeriod) {
        browserFormat += FORMAT_MAP.hour12;
      }
      else {
        browserFormat += FORMAT_MAP.hour24;
      }
    }
    else if (localeFormat[key].type === 'literal') {
      browserFormat += localeFormat[key].value;
    }
    else {
      browserFormat += FORMAT_MAP[localeFormat[key].type];
    }
  }
  return browserFormat;
};
/* equality for time-picker only accept hour and minute  */
const isTimeEqual = (firstDate, secondDate) => {
  if (!isValid(firstDate) && !isValid(secondDate)) {
    // if both dates are invalid, we return true
    return true;
  }
  else if (isValid(firstDate) !== isValid(secondDate)) {
    // if one of them is valid while the other is not, we return false
    return false;
  }
  else {
    // if both of them are valid, we compare their hour & minutes
    const sameHour = getHours(firstDate) === getHours(secondDate); // isSameHour from datefns is not working properly
    const sameMinute = getMinutes(firstDate) === getMinutes(secondDate); // isSameMinutes() from datefns is not working properly
    return sameHour && sameMinute;
  }
};
const moveTimeToToday = (movingTime, ref) => {
  return subMinutes(set(ref, {
    hours: movingTime.getUTCHours(),
    minutes: movingTime.getUTCMinutes(),
    seconds: 0,
    milliseconds: 0
  }), movingTime.getTimezoneOffset()).getTime();
};
const moveTimeToTodayUTC = (movingTime, ref) => {
  return set(ref, {
    hours: movingTime.getUTCHours(),
    minutes: movingTime.getUTCMinutes(),
    seconds: 0,
    milliseconds: 0
  }).getTime();
};
const getTimeInterval = (step, minTime, maxTime) => eachMinuteOfInterval({ start: minTime, end: maxTime }, { step }).map(dateObj => dateObj.getTime());
const startOfDayUTC = (date) => {
  const _dateObj = new Date(date);
  return subMinutes(startOfDay(_dateObj), _dateObj.getTimezoneOffset()).getTime();
};
const endOfDayUTC = (date) => {
  const _dateObj = new Date(date);
  return subMinutes(endOfDay(_dateObj), _dateObj.getTimezoneOffset()).getTime();
};

const ERROR_MESSAGES = {
  NOT_SELECTABLE: 'value-not-selectable',
  PATTERN_MISMATCH: 'pattern-mismatch',
  TYPE_MISMATCH: 'type-mismatch',
  MAX_VAL: 'range-overflow',
  MIN_VAL: 'range-underflow',
  MIN_LENGTH: 'too-short',
  MAX_LENGTH: 'too-long',
  REQUIRED: 'value-missing'
};
/* eslint-disable */
const regexEmail = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
const regexUrl = /^((https?|ftp):)?\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
class Validator {
  constructor() {
    this._locale = locale;
  }
  type(type) {
    this._type = type;
    return this;
  }
  required() {
    this._required = true;
    return this;
  }
  optional() {
    this._required = false;
    return this;
  }
  minLength(len) {
    this._minLength = isPositiveNumber(len) ? +len : undefined;
    return this;
  }
  maxLength(len) {
    this._maxLength = isPositiveNumber(len) ? +len : undefined;
    return this;
  }
  min(val) {
    this._minValue = isNumber(val) ? +val : undefined;
    return this;
  }
  max(val) {
    this._maxValue = isNumber(val) ? +val : undefined;
    return this;
  }
  pattern(pattern) {
    this._pattern = pattern;
    return this;
  }
  locale(locale) {
    this._locale = locale;
    return this;
  }
  notOneOf(input) {
    this._notSelectableValues = Array.isArray(input) ? input : [];
    return this;
  }
  oneOf(input) {
    this._selectableValues = Array.isArray(input) ? input : [];
    return this;
  }
  validate(value, nativeValidity) {
    if (this.hasRequiredError(value)) {
      return [ERROR_MESSAGES.REQUIRED];
    }
    if (!this.checkType(value)) {
      if (this._type === 'number' && nativeValidity && !nativeValidity.badInput && value.length === 0) {
        return [];
      }
      else {
        return [ERROR_MESSAGES.TYPE_MISMATCH];
      }
    }
    const validationErrors = [];
    if (this._type === 'time') {
      validationErrors.push(...this.getPatternError(value));
      if (validationErrors.length !== 0 || value === '') {
        return validationErrors;
      }
      const typedValue = this.getTypedValue(value);
      validationErrors.push(...this.getMinValError(typedValue));
      validationErrors.push(...this.getMaxValError(typedValue));
      if (validationErrors.length > 0) {
        return validationErrors;
      }
      validationErrors.push(...this.getTimeNotSelectableError(typedValue));
    }
    else if (this._type === 'date') {
      validationErrors.push(...this.getPatternError(value));
      if (validationErrors.length !== 0) {
        return validationErrors;
      }
      const typedValue = this.getTypedValue(value);
      validationErrors.push(...this.getMinValError(typedValue));
      validationErrors.push(...this.getMaxValError(typedValue));
      validationErrors.push(...this.getDateNotSelectableError(typedValue));
    }
    else if (this._type === 'select') {
      const typedValue = this.getTypedValue(value);
      validationErrors.push(...this.getMinLengthError(typedValue));
      validationErrors.push(...this.getMaxLengthError(typedValue));
      validationErrors.push(...this.getNotSelectableError(value));
    }
    else {
      const typedValue = this.getTypedValue(value);
      validationErrors.push(...this.getPatternError(typedValue));
      validationErrors.push(...this.getMinLengthError(typedValue));
      validationErrors.push(...this.getMaxLengthError(typedValue));
      validationErrors.push(...this.getMinValError(typedValue));
      validationErrors.push(...this.getMaxValError(typedValue));
      validationErrors.push(...this.getNotSelectableError(value));
    }
    return validationErrors;
  }
  getTypedValue(value) {
    if (['text', 'textarea', 'email', 'password', 'url', 'tel'].includes(this._type)) {
      return `${value}`;
    }
    else if (this._type === 'date' && !Array.isArray(value)) {
      const valueArray = typeof value === 'string' ? value.split(DATE_SEPARATOR) : value;
      return Array.isArray(valueArray)
        ? valueArray.map(v => parse(v, this._pattern, new Date(this._minValue), { locale: this._locale }))
        : parse(value, this._pattern, new Date(this._minValue), { locale: this._locale });
    }
    else if (this._type === 'time' && !Array.isArray(value)) {
      return subMinutes(parse(value, this._pattern, startOfDay(new Date()), { locale: this._locale }), new Date().getTimezoneOffset());
    }
    else if (this._type === 'number' && !Array.isArray(value)) {
      return isNumber(value) ? +value : Number.NaN;
    }
    else {
      return value;
    }
  }
  checkType(value) {
    switch (this._type) {
      case 'date':
      case 'time': {
        return true;
      }
      case 'number': {
        return isNumber(value);
      }
      case 'email': {
        return regexEmail.test(`${value}`);
      }
      case 'url': {
        return regexUrl.test(`${value}`);
      }
      case 'text':
      case 'textarea':
      case 'password':
      case 'tel': {
        return isDefined(value) ? typeof value === 'string' : true;
      }
      case 'select': {
        return Array.isArray(value);
      }
      default: {
        return true;
      }
    }
  }
  hasRequiredError(value) {
    return this._required ? !(value === null || value === void 0 ? void 0 : value.length) : false;
  }
  getMinLengthError(value) {
    switch (this._type) {
      case 'email':
      case 'password':
      case 'url':
      case 'tel':
      case 'textarea':
      case 'text': {
        return this.eligibleToMinMaxLengthCheck('min') ? (value.length < this._minLength ? ['too-short'] : []) : [];
      }
      case 'select': {
        return this.eligibleToMinMaxLengthCheck('min') && Array.isArray(value) ? (value.length < this._minLength ? ['too-short'] : []) : [];
      }
      default: {
        return [];
      }
    }
  }
  getMaxLengthError(value) {
    switch (this._type) {
      case 'email':
      case 'password':
      case 'url':
      case 'tel':
      case 'textarea':
      case 'text': {
        return this.eligibleToMinMaxLengthCheck('max') ? (value.length > this._maxLength ? ['too-long'] : []) : [];
      }
      case 'select': {
        return this.eligibleToMinMaxLengthCheck('max') && Array.isArray(value) ? (value.length > this._maxLength ? ['too-long'] : []) : [];
      }
      default: {
        return [];
      }
    }
  }
  getMinValError(value) {
    switch (this._type) {
      case 'date': {
        const hasMinError = this.eligibleToMinMaxValueCheck('max')
          ? Array.isArray(value)
            ? value.some(v => v < this._minValue)
            : value < this._minValue
          : false;
        return hasMinError ? [ERROR_MESSAGES.MIN_VAL] : [];
      }
      case 'time':
      case 'number': {
        return this.eligibleToMinMaxValueCheck('min') ? (value < this._minValue ? [ERROR_MESSAGES.MIN_VAL] : []) : [];
      }
      default: {
        return [];
      }
    }
  }
  getMaxValError(value) {
    switch (this._type) {
      case 'date': {
        const hasMaxError = this.eligibleToMinMaxValueCheck('max')
          ? Array.isArray(value)
            ? value.some(v => v > this._maxValue)
            : value > this._maxValue
          : false;
        return hasMaxError ? [ERROR_MESSAGES.MAX_VAL] : [];
      }
      case 'time':
      case 'number': {
        return this.eligibleToMinMaxValueCheck('max') ? (value > this._maxValue ? [ERROR_MESSAGES.MAX_VAL] : []) : [];
      }
      default: {
        return [];
      }
    }
  }
  hasTimePatternError(value) {
    return value && !isMatch(value === null || value === void 0 ? void 0 : value.toString(), this._pattern, { locale: this._locale });
  }
  hasDatePatternError(value) {
    const valueArray = typeof value === 'string' && value.split(DATE_SEPARATOR);
    if (Array.isArray(valueArray)) {
      return valueArray.some((v) => v && !isMatch(v === null || v === void 0 ? void 0 : v.toString(), this._pattern, { locale: this._locale }));
    }
    else if (Array.isArray(value)) {
      return value.some((v) => v && !isMatch(v === null || v === void 0 ? void 0 : v.toString(), this._pattern, { locale: this._locale }));
    }
    else {
      return value && !isMatch(value === null || value === void 0 ? void 0 : value.toString(), this._pattern, { locale: this._locale });
    }
  }
  getPatternError(value) {
    let errorFound;
    if (['text', 'email', 'password', 'url', 'tel', 'number'].includes(this._type)) {
      const patternRegex = typeof this._pattern === 'string' ? new RegExp(this._pattern) : this._pattern;
      errorFound = patternRegex ? !patternRegex.test(`${value}`) : false;
    }
    else if (this._type === 'time') {
      errorFound = this.hasTimePatternError(value);
    }
    else if (this._type === 'date') {
      errorFound = this.hasDatePatternError(value);
    }
    else {
      errorFound = false;
    }
    return errorFound ? [ERROR_MESSAGES.PATTERN_MISMATCH] : [];
  }
  getNotSelectableError(value) {
    return this.isOneOfNotSelectableValues(value) || this.isNotOneOfSelectableValues(value) ? [ERROR_MESSAGES.NOT_SELECTABLE] : [];
  }
  isOneOfNotSelectableValues(value) {
    var _a;
    return ((_a = this._notSelectableValues) === null || _a === void 0 ? void 0 : _a.length) > 0 && value
      ? this._notSelectableValues.some(notSelectableValue => this.isSame(notSelectableValue, value))
      : false;
  }
  isNotOneOfSelectableValues(value) {
    var _a;
    return ((_a = this._selectableValues) === null || _a === void 0 ? void 0 : _a.length) > 0 && value
      ? !this._selectableValues.some(selectableValue => this.isSame(selectableValue, value))
      : false;
  }
  eligibleToMinMaxLengthCheck(type) {
    const hasMinLength = isPositiveNumber(this._minLength);
    const hasMaxLength = isPositiveNumber(this._maxLength);
    if (hasMinLength && hasMaxLength) {
      return this._maxLength > this._minLength;
    }
    else {
      return type === 'min' ? hasMinLength : hasMaxLength;
    }
  }
  eligibleToMinMaxValueCheck(type) {
    const hasMinVal = isNumber(this._minValue);
    const hasMaxVal = isNumber(this._maxValue);
    if (hasMinVal && hasMaxVal) {
      return this._maxValue > this._minValue;
    }
    else {
      return type === 'min' ? hasMinVal : hasMaxVal;
    }
  }
  isSame(i1, i2) {
    if (Array.isArray(i2)) {
      return i2.some(item => this.isSame(i1, item));
    }
    else {
      const i1Fixed = i1 instanceof Date ? i1.getTime() : i1;
      const i2Fixed = i2 instanceof Date ? i2.getTime() : i2;
      return i1Fixed === i2Fixed;
    }
  }
  getDateNotSelectableError(typedValue) {
    var _a;
    const disabledDateErrors = [];
    const disabledDates = ((_a = this._notSelectableValues) === null || _a === void 0 ? void 0 : _a.length) > 0
      ? this._notSelectableValues.map(notSelectableValue => typeof notSelectableValue === 'string'
        ? parse(notSelectableValue, this._pattern, new Date(this._minValue), { locale: this._locale })
        : notSelectableValue)
      : [];
    Array.isArray(typedValue)
      ? typedValue.forEach((v, i) => disabledDates.some(disabledDate => {
        const foundDisabledDateInGivenInterval = isAfter(disabledDate, v) && i < typedValue.length - 1 && isBefore(disabledDate, typedValue[i + 1]);
        return this.isSame(disabledDate, v) || foundDisabledDateInGivenInterval;
      }) && disabledDateErrors.push(ERROR_MESSAGES.NOT_SELECTABLE))
      : this.isOneOfNotSelectableValues(typedValue) && disabledDateErrors.push(ERROR_MESSAGES.NOT_SELECTABLE);
    return disabledDateErrors;
  }
  getTimeNotSelectableError(typedValue) {
    const isOneOfNotSelectableValues = this._notSelectableValues.some(e => isTimeEqual(e, new Date(typedValue)));
    const isNotOneOfSelectableValues = this._selectableValues.length > 0 && !this._selectableValues.some(e => isTimeEqual(e, new Date(typedValue)));
    return isOneOfNotSelectableValues || isNotOneOfSelectableValues ? [ERROR_MESSAGES.NOT_SELECTABLE] : [];
  }
}

export { FORMAT_MAP as F, Validator as V, moveTimeToTodayUTC as a, set as b, addMinutes as c, defineFormat as d, endOfDayUTC as e, subMinutes as f, getTimeInterval as g, handleLocation as h, isTimeEqual as i, moveTimeToToday as m, startOfDayUTC as s };
